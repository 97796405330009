import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

function ProjectDateRangePicker({ table, project, onDateRangeChange }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (!table || !project) return;

    // Määrittele asynkroninen funktio koukun sisällä
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_ENDPOINT_PROJECTDATES + table + '/' + project, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ table, project })
        });
        const data = await response.json();

        const newEndDate = new Date(data.maxDate); // Projektin viimeinen päivä
        newEndDate.setHours(23, 59, 59, 999); // Aseta päivämäärä päivän loppuun
        const newStartDate = new Date(newEndDate);
        newStartDate.setHours(0, 0, 0, 0); // Aseta päivämäärä päivän alkuun
        newStartDate.setDate(newStartDate.getDate() - 7); // 7 päivää ennen projektin viimeistä päivää

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        onDateRangeChange(newStartDate, newEndDate);
      } catch (error) {
        console.error("Error fetching project dates:", error);
      }
    };

    // Kutsu asynkronista funktiota
    fetchData();
  }, [project, table, onDateRangeChange]);

  return (
    <div className="date-picker-container">
      <button onClick={() => {
          let newStartDate = new Date(startDate);
          newStartDate.setDate(newStartDate.getDate() - 7);

          let newEndDate = new Date(endDate);
          newEndDate.setDate(newEndDate.getDate() - 7);

          setStartDate(newStartDate);
          setEndDate(newEndDate);
          onDateRangeChange(newStartDate, newEndDate); // Käytä uusia päivämääriä
      }}><img className="date-control-img" src={require('../images/prev.png')} alt="Move Backward by a Week" />
      </button>

      <div className="date-picker">
        <DatePicker
          selected={startDate}
          onChange={date => {
            setStartDate(date);
            onDateRangeChange(date, endDate); // Päivitä aloituspäivämäärä
          }}
          dateFormat="yyyy-MM-dd"
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          selected={endDate}
          onChange={date => {
            setEndDate(date);
            onDateRangeChange(startDate, date); // Päivitä lopetuspäivämäärä
          }}
          dateFormat="yyyy-MM-dd"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      <button onClick={() => {
          let newStartDate = new Date(startDate);
          newStartDate.setDate(newStartDate.getDate() + 7);

          let newEndDate = new Date(endDate);
          newEndDate.setDate(newEndDate.getDate() + 7);

          setStartDate(newStartDate);
          setEndDate(newEndDate);
          onDateRangeChange(newStartDate, newEndDate); // Käytä uusia päivämääriä
      }}><img className="date-control-img" src={require('../images/next.png')} alt="Move Forward by a Week" />
      </button>
    </div>
  );
}

export default ProjectDateRangePicker;
