import React from 'react';
import '../css/footer.css';
import simsonarLogo from '../images/SIMSONAR-Logo-white-500px.png';

function Footer() {
    return (
        <footer className="footer">
            <img className="footer-logo" src={simsonarLogo} alt="Simsonar Logo" />
            <p>&copy; {new Date().getFullYear()} Simsonar</p>
            <div>
                <p><a style={{color: "white"}} href="https://www.simsonar.com/simsonar_privacy_policy.html">Terms and Conditions</a></p>
                <p><a style={{color: "white"}} href="https://www.simsonar.com/simsonar_terms_and_conditions.html">Privacy policy</a></p>
            </div>
            <div>
                <address>
                    Simsonar Oy <br />
                    Kiilakiventie 1 <br />
                    90250 Oulu <br />
                    Finland <br />
                </address>
            </div>
            <div>
                <p><a style={{color: "white"}} href="mailto:simsonar@simsonar.com">Email to Simsonar</a></p>
            </div>
            <div>
                <p>tel: +358 40 516 2244</p>
            </div>
        </footer>
    );
}

export default Footer;