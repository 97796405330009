import React, { useState } from 'react';
import '../css/Login.css';
import simsonarLogo from '../images/SIMSONAR-Logo-white-500px.png';

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_LOGIN}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Network response was not ok');
      }

      const data = await response.json();
      if (data.message === 'Login successful!') {
        onLoginSuccess(username, data.isAdmin, data.tables);
      } else {
        setError(data.message || 'Invalid username or password');
      }
    } catch (error) {
      setError('Login failed: ' + error.message);
    }
  };

  return (
    <div className="login-page">
      <div className="logoContainer">
        <img src={simsonarLogo} alt="Simsonar Logo" className="logoImage" />
      </div>
      <div className="stripeBackground">
        Camera reporting
      </div>
      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <div>
            <label>Username</label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div>
            <label>Password</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <button type="submit">Login</button>
          {error && <p>{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Login;
