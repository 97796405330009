import React from 'react';
import ReactModal from 'react-modal';
import '../css/Modal.css';

function TableSelect({ tables, onTableChange, className, isModalOpen, closeModal }) {
    const handleTableSelection = (table) => {
        onTableChange(table);
        closeModal();  // Close the modal after selection
    };

    if (!tables || tables.length === 0) {
        return <div>No tables available.</div>;
    }

    return (
        <div className={className}>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal} // Allow closing on request
                shouldCloseOnOverlayClick={true} // Allow closing on overlay click
                shouldCloseOnEsc={true} // Allow closing on Esc key
                contentLabel="Select a Table"
                className="modal-content"
                overlayClassName="Overlay"
            >
                <h2>Select a Table</h2>
                <ul className="list">
                    {tables.map((table, index) => (
                        <li key={index} onClick={() => handleTableSelection(table)}>
                            {table}
                            <span>&gt;</span>
                        </li>
                    ))}
                </ul>
                <button onClick={closeModal} className="modal-button">Close</button>
            </ReactModal>
        </div>
    );
}

export default TableSelect;
