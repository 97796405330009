import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

function SpeciesPeriodChart({ table, project, startDate, endDate, isStatisticOnly}) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const formatURLDate = (date) => {
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_SPECIESPERIOD}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              table,
              project,
              startDate: formatURLDate(startDate),
              endDate: formatURLDate(endDate)
            })
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const labels = Object.keys(data); // Oletan, että data on objekti, jossa avaimet ovat lajeja
        const counts = Object.values(data); // ja arvot ovat niiden määriä

        const formattedData = {
          labels: labels,
          datasets: [
            {
              label: 'Species by period',
              data: counts,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        };

        setChartData(formattedData);
      } catch (error) {
        console.error('Error fetching species data:', error);
      }
    };

    if (table && project) {
      fetchData();
  }
  }, [table, project, startDate, endDate]);

  if (!chartData) return null;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className={`chartContainer ${isStatisticOnly ? 'isStatisticOnly' : ''}`}>
        <Bar
            data={chartData}
            options={options}
        />
    </div>
);
}


export default SpeciesPeriodChart;