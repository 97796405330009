import React, { useState } from 'react';
import ReactModal from 'react-modal';
import '../css/Modal.css';

const ChangePasswordModal = ({ isModalOpen, closeModal, username }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const handleChangePassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, currentPassword, newPassword }),
            });
            const result = await response.json();

            if (result.success) {
                setMessage('Password changed successfully.');
                setIsError(false);
            } else {
                setMessage(result.message || 'Failed to change password.');
                setIsError(true);
            }
        } catch (error) {
            console.error('Error changing password:', error);
            setMessage('Error changing password.');
            setIsError(true);
        }
    };

    return (
        <ReactModal 
            isOpen={isModalOpen} 
            onRequestClose={closeModal} 
            contentLabel="Change Password"
            className="modal-content"
            overlayClassName="Overlay"
        >
            <h2>Change Password</h2>
            {message && <p className={isError ? 'error-message' : 'success-message'}>{message}</p>}
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <label>Current Password:</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        className="modal-input"
                    />
                </div>
                <div className="form-group">
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="modal-input"
                    />
                </div>

                <button type="button" onClick={handleChangePassword} className="modal-button">
                    Change Password
                </button>
                <button type="button" onClick={closeModal} className="modal-button">
                    Close
                </button>
            </form>
        </ReactModal>
    );
};

export default ChangePasswordModal;
