import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './css/App.css';
import DataTable from './components/DataTable';
import Navbar from './components/Navbar';
import ChartTabs from './components/ChartTabs';
import Footer from './components/Footer';
import Login from './components/Login';
import ProjectSelect from './components/ProjectSelect';
import TableSelect from './components/TableSelect';
import UserManagementModal from './components/AdminModal';
import ChangePasswordModal from './components/ChangePasswordModal';
import ReactModal from 'react-modal';

function App() {
    const [user, setUser] = useState({
        loggedIn: false,
        username: '',
        isAdmin: false,
        tables: [],
        projects: [],
        selectedTable: '',
        selectedProject: '',
    });

    const [isGuest, setIsGuest] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });
    const [isStatisticOnly, setIsStatisticOnly] = useState(false);
    const [isTableModalOpen, setIsTableModalOpen] = useState(false);
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

    const logoutIntentionallyRef = useRef(false);

    useEffect(() => {
        ReactModal.setAppElement('#root');
    }, []);

    const onLoginSuccess = useCallback((username, isAdmin, tables) => {
        const selectedTable = tables.length > 0 ? tables[0] : '';
        setUser({
            loggedIn: true,
            username,
            isAdmin,
            tables,
            projects: [],
            selectedTable,
            selectedProject: ''
        });
        if (isAdmin) {
            setIsTableModalOpen(true);
        } else {
            setIsProjectModalOpen(true);
        }
    }, []);

    const handleGuestAccess = useCallback(async () => {
        const guestTable = 'fortum_se';
        setIsGuest(true);
        setUser({
            loggedIn: false,
            username: 'spjutmo_guest',
            isAdmin: false,
            tables: [guestTable],
            projects: [],
            selectedTable: guestTable,
            selectedProject: ''
        });
        setIsProjectModalOpen(true);

        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login/guest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Error logging guest access:', error);
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname === '/spjutmo') {
            handleGuestAccess();
        }
    }, [handleGuestAccess]);

    const handleTableChange = useCallback((table) => {
        setUser(prevUser => ({ ...prevUser, selectedTable: table, selectedProject: '', projects: [] }));
        console.log("Updated selectedTable:", table);
        setIsTableModalOpen(false);
        setIsProjectModalOpen(true);
    }, []);

    const handleProjectChange = useCallback((project) => {
        setUser(prevUser => ({
            ...prevUser,
            selectedProject: '',
        }));
        setTimeout(() => {
            setUser(prevUser => ({ ...prevUser, selectedProject: project }));
            setIsProjectModalOpen(false);
        }, 0);
    }, []);

    const handleDateRangeChange = useCallback((start, end) => {
        setDateRange({ startDate: start, endDate: end });
    }, []);

    const handleLogout = useCallback(async (username) => {
        logoutIntentionallyRef.current = true;
        try {
            if (!isGuest) {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username }),
                });
            }

            setUser({
                loggedIn: false,
                username: '',
                isAdmin: false,
                tables: [],
                projects: [],
                selectedTable: '',
                selectedProject: '',
            });
            window.location.href = '/';
        } catch (error) {
            console.error('Error logging out:', error);
        }
    }, [isGuest]);

    const openChangePasswordModal = () => {
        setIsChangePasswordModalOpen(true);
    };

    const closeChangePasswordModal = () => {
        setIsChangePasswordModalOpen(false);
    };

    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            if (!logoutIntentionallyRef.current && user.username) {
                try {
                    await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login/disconnect`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ username: user.username }),
                    });
                } catch (error) {
                    console.error('Error logging disconnect:', error);
                }
            }
            if (isGuest) {
                try {
                    await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login/guest-disconnect`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                } catch (error) {
                    console.error('Error logging guest disconnect:', error);
                }
            }
        };

        if (user.loggedIn || isGuest) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [user.loggedIn, user.username, isGuest]);

    if (!user.loggedIn && !isGuest) {
        return <Login onLoginSuccess={onLoginSuccess} />;
    }

    return (
        <Routes>
            <Route path="/spjutmo" element={<Navigate to="/" replace />} />
            <Route path="/" element={
                <div className="App">
                    <div className="main-container">
                        <Navbar
                            username={user.username}
                            onTableChange={handleTableChange}
                            onProjectChange={handleProjectChange}
                            onDateRangeChange={handleDateRangeChange}
                            isStatisticOnly={isStatisticOnly}
                            toggleStatisticOnly={() => setIsStatisticOnly(!isStatisticOnly)}
                            selectedTable={user.selectedTable}
                            selectedProject={user.selectedProject}
                            isAdmin={user.isAdmin}
                            isGuest={isGuest}
                            tables={user.tables}
                            openTableModal={() => !isGuest && setIsTableModalOpen(true)}
                            openProjectModal={() => setIsProjectModalOpen(true)}
                            openUserModal={() => setIsUserModalOpen(true)}
                            handleLogout={handleLogout}
                            openChangePasswordModal={openChangePasswordModal}
                        />
                        <div className="main-content">
                            {!isStatisticOnly && user.selectedTable && user.selectedProject && (
                                <DataTable
                                    table={user.selectedTable}
                                    project={user.selectedProject}
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                />
                            )}
                            {user.selectedTable && user.selectedProject && (
                                <ChartTabs
                                    selectedTable={user.selectedTable}
                                    selectedProject={user.selectedProject}
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    isStatisticOnly={isStatisticOnly}
                                />
                            )}
                        </div>
                        <Footer />
                    </div>
                    {!isGuest && (
                        <TableSelect
                            tables={user.tables}
                            onTableChange={handleTableChange}
                            className="table-select"
                            isModalOpen={isTableModalOpen}
                            closeModal={() => setIsTableModalOpen(false)}
                        />
                    )}
                    <ProjectSelect
                        username={user.username}
                        selectedTable={user.selectedTable}
                        onProjectChange={handleProjectChange}
                        className="project-select"
                        isModalOpen={isProjectModalOpen}
                        closeModal={() => setIsProjectModalOpen(false)}
                    />
                    <UserManagementModal
                        isModalOpen={isUserModalOpen}
                        closeModal={() => setIsUserModalOpen(false)}
                    />
                    <ChangePasswordModal
                        isModalOpen={isChangePasswordModalOpen}
                        closeModal={closeChangePasswordModal}
                        username={user.username}
                    />
                </div>
            } />
        </Routes>
    );
}

export default App;
