import React, { useState, useEffect } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import '../css/DataTable.css';

const DataTable = ({ table, project, startDate, endDate }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [sortDirection, setSortDirection] = useState('asc');
  const [sortField, setSortField] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  const handleImageError = (e) => {
    e.target.src = require('../images/NoPictureAvailable.png');
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_DATATABLE}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ table, project, startDate, endDate }),
        }
      );
      const jsonData = await response.json();
      setSortedData(jsonData);

      if (jsonData.length > 0) {
        setSelectedImageIndex(0);
      } else {
        setSelectedImageIndex(null);
      }
    };

    if (table && project) {
      fetchData();
    }
  }, [table, project, startDate, endDate]);

  const handleSort = (field) => {
    const sorted = [...sortedData].sort((a, b) => {
      let valA = a[field];
      let valB = b[field];

      if (field === 'Time') {
        const [hourA, minuteA] = valA.split(":").map(Number);
        const [hourB, minuteB] = valB.split(":").map(Number);
        valA = hourA * 60 + minuteA;
        valB = hourB * 60 + minuteB;
      }

      if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
      if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setSortField(field);
  };

  const Row = ({ index, key, style }) => {
    const row = sortedData[index];
    const isSelected = index === selectedImageIndex;
    const imageUrl = row.ImageURL;
    const videoUrl = imageUrl && imageUrl.replace('.jpg', '.mp4');
    const isVideoAvailable = imageUrl && imageUrl.endsWith('.mp4');

    const displayMedia = isVideoAvailable ? (
      <video
        width={40}
        height={30}
        preload="metadata"
        muted
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={imageUrl || require('../images/NoPictureAvailable.png')}
        alt=""
        width={40}
        height={30}
        onError={handleImageError}
      />
    );

    return (
      <div
        key={key}
        style={style}
        className={`row ${isSelected ? 'selected' : ''}`}
        onClick={() => setSelectedImageIndex(index)}
      >
        {['ImageURL', 'Species', 'Length', 'U_D', 'Date', 'Time', 'File'].map((field, colIndex) => (
          <div className="cell" key={colIndex}>
            {field === 'ImageURL' ? displayMedia : row && row[field]}
          </div>
        ))}
      </div>
    );
  };

  const handleImageClick = (e) => {
    const image = e.target;

    if (image.requestFullscreen) {
      image.requestFullscreen();
    } else if (image.mozRequestFullScreen) { /* Firefox */
      image.mozRequestFullScreen();
    } else if (image.webkitRequestFullscreen) { /* Chrome, Safari, and Opera */
      image.webkitRequestFullscreen();
    } else if (image.msRequestFullscreen) { /* IE/Edge */
      image.msRequestFullscreen();
    }
  };

  return (
    <div className="dataTableContainer">
      <div className="header">
        <img className="icon-charts" src={require("../images/icon-traffic.png")} alt="Logo" />
        Detections
      </div>
      <div className="contentContainer">
        <div className="tableContainer">
          <div style={{ width: '100%', overflowX: 'auto' }}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <>
                  <div style={{ width: Math.max(width, 1000) }}>
                    <div className="row table-header">
                      {['Image', 'Species', 'Length', 'Up/Down', 'Date', 'Time', 'File'].map((headCell, index) => (
                        <div className="cell" key={index} onClick={() => handleSort(headCell)}>
                          {headCell}
                          {sortField === headCell && <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>}
                        </div>
                      ))}
                    </div>
                  </div>
                  <List
                    height={450}
                    rowCount={sortedData.length}
                    rowHeight={30}
                    rowRenderer={Row}
                    width={Math.max(width, 1000)}
                  />
                </>
              )}
            </AutoSizer>
          </div>
        </div>
        <div className="selectedImageContainer">
          {selectedImageIndex !== null && (
            sortedData[selectedImageIndex]?.ImageURL.endsWith('.mp4') ? (
              <video
                src={sortedData[selectedImageIndex]?.ImageURL}
                className="selectedMedia"
                controls
                autoPlay
                muted
                onClick={handleImageClick}
              />
            ) : (
              <img
                src={sortedData[selectedImageIndex]?.ImageURL || require('../images/NoPictureAvailable.png')}
                alt=""
                className="selectedMedia"
                onClick={handleImageClick}
                onError={handleImageError}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTable;
