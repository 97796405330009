import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';


function UpDownSeasonChart({ table, project, isStatisticOnly }) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_UPDOWNSEASON}${table}/${project}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ table, project })
        });

        const data = await response.json();
        const chartFormat = {
          labels: data.map(d => new Date(d.Date).toLocaleDateString()),
          datasets: [
            {
              type: 'bar',
              label: 'Up',
              data: data.map(d => d.up),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              order: 1
            },
            {
              type: 'bar',
              label: 'Down',
              data: data.map(d => d.down),
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              order: 2
            },
            {
              type: 'line',
              label: 'Avg Temp',
              data: data.map(d => d.avgTemp),
              fill: false,
              backgroundColor: '#ed0909',
              borderColor: '#ed0909',
              borderWidth: 1,
              order: 3,
              yAxisID: 'y1'
            }
          ]
        };
        setChartData(chartFormat);
      } catch (error) {
        console.error("Error fetching data for UpDownSeasonChart:", error);
      }
    };

    if (table && project) {
      fetchData();
  }
  }, [table, project]);

  if (!chartData) return null;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      },
      y1: {
        position: 'right',
        beginAtZero: true,
        min: 0,
        max: 40
      }
    }
  };

  return (
    <div className={`chartContainer ${isStatisticOnly ? 'isStatisticOnly' : ''}`}>
        <Bar
            data={chartData}
            options={options}
        />
    </div>
);
}

export default UpDownSeasonChart;
