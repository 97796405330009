import React from 'react';
import UpDownSeasonChart from '../charts/UpDownSeasonChart';
import UpDownPeriodChart from '../charts/UpDownPeriodChart';
import SizesSeasonChart from '../charts/SizesSeasonChart';
import SizesPeriodChart from '../charts/SizesPeriodChart';
import SpeciesSeasonChart from '../charts/SpeciesSeasonChart';
import SpeciesPeriodChart from '../charts/SpeciesPeriodChart';
import '../css/Chart.css';

function ChartTabs({ selectedTable, selectedProject, startDate, endDate, isStatisticOnly }) {
    const [value, setValue] = React.useState(0);
    const handleButtonClick = (index, event) => {
        event.preventDefault();
        setValue(index);
    };
    const chartComponents = [
        UpDownSeasonChart,
        UpDownPeriodChart,
        SizesSeasonChart,
        SizesPeriodChart,
        SpeciesSeasonChart,
        SpeciesPeriodChart
    ];

    const ChartComponent = chartComponents[value];

    return (
        <div className="chartTabs-container">
            <div className="chartTabs-header">
                <img className="icon-charts" src={require("../images/icon-charts.png")} alt="Logo" />
                Statistics
            </div>

            {/* Tabs */}
            <div className="chartTabs-selector">
                {['Up/Down/Season', 'Up/Down/Period', 'Sizes/Season', 'Sizes/Period', 'Species/Season', 'Species/Period'].map((label, index) => (
                    <button type="button" key={label} onClick={(e) => handleButtonClick(index, e)} className={value === index ? 'chartTab-active' : 'chartTab'}>
                        {label}
                    </button>
                ))}
            </div>

            {/* Charts */}
            <ChartComponent table={selectedTable} project={selectedProject} startDate={startDate} endDate={endDate} isStatisticOnly={isStatisticOnly}/>
        </div>
    );
}

export default ChartTabs;
