import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import '../css/Modal.css';

function ProjectSelect({ selectedTable, onProjectChange, className, isModalOpen, closeModal }) {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (!selectedTable) {
            console.log("No table selected, skipping project fetch.");
            return;
        }

        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/projectValues/${selectedTable}`);
                const data = await response.json();
                setProjects(Array.isArray(data) ? data : []); // Varmista, että data on taulukko
                console.log("Projects fetched successfully.");
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        console.log(`Fetching projects for table: ${selectedTable}`);
        fetchProjects();
    }, [selectedTable]);

    const handleProjectSelection = (project) => {
        onProjectChange(project);
        closeModal();  // Close the modal after selection
    };

    if (!selectedTable) {
        return <div>Please select a table to see projects.</div>;
    }

    return (
        <div className={className}>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal} // Allow closing on request
                shouldCloseOnOverlayClick={true} // Allow closing on overlay click
                shouldCloseOnEsc={true} // Allow closing on Esc key
                contentLabel="Select a Project"
                className="modal-content"
                overlayClassName="Overlay"
            >
                <h2>Select a Project</h2>
                <ul className="list">
                    {projects.map((project, index) => (
                        <li key={index} onClick={() => handleProjectSelection(project)}>
                            {project}
                            <span>&gt;</span>
                        </li>
                    ))}
                </ul>
                <button onClick={closeModal} className="modal-button">Close</button>
            </ReactModal>
        </div>
    );
}

export default ProjectSelect;
