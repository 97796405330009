import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import '../css/Modal.css';

const UserManagementModal = ({ isModalOpen, closeModal }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [client, setClient] = useState('');
    const [deleteUsername, setDeleteUsername] = useState('');
    const [createError, setCreateError] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [userList, setUserList] = useState([]);
    const [clientList, setClientList] = useState([]);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/all`);
            const data = await response.json();
            setUserList(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchClients = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tables`);
            const data = await response.json();
            setClientList(data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchClients();
    }, []);

    const handleCreateUser = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, client }),
            });

            if (response.status === 201) {
                alert('User created successfully');
                setUsername('');
                setPassword('');
                setClient('');
                setCreateError('');
                fetchUsers(); // Refresh user list after creating a new user
            } else if (response.status === 409) {
                setCreateError('User already exists');
            } else {
                const data = await response.json();
                setCreateError(data.message || 'Error creating user');
            }
        } catch (error) {
            console.error('Error creating user:', error);
            setCreateError('Error creating user: ' + error.message);
        }
    };

    const handleDeleteUser = async () => {
        const confirmation = window.confirm(`Are you sure you want to delete user ${deleteUsername}?`);
        if (!confirmation) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/delete/${deleteUsername}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                alert('User deleted successfully');
                setDeleteUsername('');
                setDeleteError('');
                fetchUsers(); // Refresh user list after deleting a user
            } else if (response.status === 404) {
                setDeleteError('User does not exist');
            } else {
                setDeleteError('Error deleting user');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setDeleteError('Some other error occurred');
        }
    };

    return (
        <ReactModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="User Management"
            className="modal-content"
            overlayClassName="Overlay"
        >
            <h2>User Management</h2>
            <div>
                <h3>Create User</h3>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="modal-input"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="modal-input"
                />
                <select
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                    className="modal-input"
                >
                    <option value="">Select Client</option>
                    {clientList.map((client) => (
                        <option key={client} value={client}>
                            {client}
                        </option>
                    ))}
                </select>
                <button onClick={handleCreateUser} className="modal-button">Create User</button>
                {createError && <p>{createError}</p>}
            </div>
            <div>
                <h3>Delete User</h3>
                <select
                    value={deleteUsername}
                    onChange={(e) => setDeleteUsername(e.target.value)}
                    className="modal-input"
                >
                    <option value="">Select User</option>
                    {userList.map((user) => (
                        <option key={user} value={user}>
                            {user}
                        </option>
                    ))}
                </select>
                <button onClick={handleDeleteUser} className="modal-button">Delete User</button>
                {deleteError && <p>{deleteError}</p>}
            </div>
            <button onClick={closeModal} className="modal-button">Close</button>
        </ReactModal>
    );
};

export default UserManagementModal;
