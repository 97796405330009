import React, { memo, useCallback } from 'react';
import '../css/navbar.css';
import '../css/Modal.css';
import ProjectDateRangePicker from './ProjectDateRangePicker';
import logoImage from '../images/SIMSONAR-Logo-white-500px.png';

const Navbar = memo(({ username, onDateRangeChange, isStatisticOnly, toggleStatisticOnly, selectedTable, selectedProject, isAdmin, openTableModal, openProjectModal, handleLogout, openUserModal, openChangePasswordModal, isGuest }) => {
    const handleToggle = useCallback(() => toggleStatisticOnly(), [toggleStatisticOnly]);

    const handleDateRangeChange = useCallback((start, end) => onDateRangeChange(start, end), [onDateRangeChange]);

    return (
        <div className="navbar">
            <div className="navbar-content">
                <div className="left-buttons">
                    <img className="navbar-logo" src={logoImage} alt="Simsonar Logo" />
                    {isAdmin && (
                        <button onClick={openTableModal} className="open-modal-button">
                            {selectedTable ? `${selectedTable}` : 'Select Table'}
                        </button>
                    )}
                    {selectedTable && (
                        <button onClick={openProjectModal} className="open-modal-button">
                            {selectedProject ? `${selectedProject}` : 'Select Project'}
                        </button>
                    )}
                    <button className='statistic-link' onClick={handleToggle}>
                        {isStatisticOnly ? "Show All Data" : "Show Statistic Only"}
                    </button>
                </div>
                <div className="right-buttons">
                    {isAdmin && (
                        <button onClick={openUserModal} className="open-modal-button">Manage Users</button>
                    )}
                    {!isGuest && (
                        <button onClick={openChangePasswordModal} className='open-modal-button'>
                            Change Password
                        </button>
                    )}
                    <button onClick={() => handleLogout(username)} className='quit'>
                        Log out
                    </button>
                </div>
            </div>
            <div className="bottom-part">
                <span className="period-text">PERIOD:</span>
                <ProjectDateRangePicker table={selectedTable} project={selectedProject} onDateRangeChange={handleDateRangeChange} />
            </div>
        </div>
    );
});

export default Navbar;
