import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

function SpeciesSeasonChart({ table, project, isStatisticOnly}) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_SPECIESSEASON}${table}/${project}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ table, project })
          }
        );

        const data = await response.json();

        const speciesNames = Object.keys(data);
        const speciesCounts = Object.values(data);

        const chartFormat = {
          labels: speciesNames,
          datasets: [
            {
              label: 'Species by season',
              data: speciesCounts,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }
          ]
        };

        setChartData(chartFormat);
      } catch (error) {
        console.error("Error fetching species data:", error);
      }
    };

    if (table && project) {
      fetchData();
  }
  }, [table, project]);

  if (!chartData) return null;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className={`chartContainer ${isStatisticOnly ? 'isStatisticOnly' : ''}`}>
        <Bar
            data={chartData}
            options={options}
        />
    </div>
);
}


export default SpeciesSeasonChart;
