import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

function SizesSeasonChart({ table, project, isStatisticOnly}) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_SIZESSEASON}${table}/${project}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ table, project })
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const chartFormat = {
          labels: Object.keys(data),
          datasets: [
            {
              label: 'Sizes by Season',
              data: Object.values(data),
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }
          ]
        };

        setChartData(chartFormat);
      } catch (error) {
        console.error("Error fetching sizes by season:", error);
      }
    };

    if (table && project) {
      fetchData();
  }
  }, [table, project]);

  if (!chartData) return null;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className={`chartContainer ${isStatisticOnly ? 'isStatisticOnly' : ''}`}>
        <Bar
            data={chartData}
            options={options}
        />
    </div>
);
}


export default SizesSeasonChart;
